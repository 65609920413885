import { OpenShiftModel } from './open-shift.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { sortByAscendingDateAndTime } from '../../../shared/sort.helper';

export interface OpenShiftState extends EntityState<OpenShiftModel> {
  isLoading?: boolean;
  error?: any;
}

export const openShiftAdapter: EntityAdapter<OpenShiftModel> = createEntityAdapter<OpenShiftModel>({
  selectId: (shift) => shift.occurrence_id,
  sortComparer: sortByAscendingDateAndTime,
});

export const initialState: OpenShiftState = openShiftAdapter.getInitialState({
  isLoading: false,
  error: null,
});
