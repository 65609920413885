import { HasDateString, HasDateStringAndTime, HasTime } from './date.helper';

export function sortByAscendingDateAndTime(object1: HasDateStringAndTime, object2: HasDateStringAndTime) {
  const dateEquality = sortByAscendingDate(object1, object2);

  if (dateEquality === 0) {
    return sortByAscendingTime(object1, object2);
  } else {
    return dateEquality;
  }
}
export function sortByAscendingTime(object1: HasTime, object2: HasTime) {
  return object1.starttime.localeCompare(object2.starttime);
}

export function sortByAscendingDate(object1: HasDateString, object2: HasDateString) {
  return object1.date.localeCompare(object2.date);
}
