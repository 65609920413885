import { UnsafeAction } from '../../interfaces';
import { EmployeeModel } from '../employee/employee.model';
import { ScheduleBase } from '../schedule/schedule.model';

export enum OpenShiftUserStatus {
  REQUESTED = 'requested',
  PENDING = 'pending',
  DECLINED = 'declined',
  ASSIGNED = 'assigned',
}

export interface OpenShiftMap {
  [id: string]: OpenShiftModel;
}

export interface OpenShiftStats {
  pending: number;
  requested: number;
  declined: number;
}

export interface OpenShiftModel extends ScheduleBase {
  EmployeeStatus: EmployeeStatusModel[];
  invitees?: string[];
  rejections?: string[];
  requests?: string[];
  Invitees?: EmployeeModel[];
  isInvited?: boolean;
  Rejections?: EmployeeModel[];
  hasRejected?: boolean;
  hasRequested?: boolean;
  instances: string;
  instances_remaining: number;
  can_pickup_open_shift: boolean;
  createdBy?: string;
  custom_fields: any;
  [propName: string]: any; // allow for custom properties

  approval_required: boolean;
  stats: OpenShiftStats;
}

export interface EmployeeStatusModel {
  employee_id: string;
  invited: boolean;
  status: OpenShiftUserStatus;
}

export interface OpenShiftsLoadRequest {
  userId?: string;
  minDate?: string;
  maxDate?: string;
  departmentId?: string | string[];
}

export interface LoadOpenShiftsSuccessAction extends UnsafeAction {
  requestData: OpenShiftsLoadRequest;
}

export interface OpenShiftMultiAssignRequestModel {
  userIds: string[];
  notify: boolean;
}
